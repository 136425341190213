import React, { FC } from 'react'

import { Layout } from '../old-components'
import { graphql, PageProps } from 'gatsby'
import { Sections } from '../components/sections/sections'
import { ILandingPageQuery } from '../types/landing-page'

type IndexPageProps = PageProps<ILandingPageQuery>

const IndexPage: FC<IndexPageProps> = ({ data }) => {
  const { contentfulLandingPage } = data
  return (
    <Layout title={contentfulLandingPage.pageTitle}>
      <Sections sections={contentfulLandingPage.sections} />
    </Layout>
  )
}

export const query = graphql`
  query IndexPageQuery($language: String) {
    contentfulLandingPage(slug: { eq: "/" }, node_locale: { eq: $language }) {
      internalName
      pageTitle
      sections {
        __typename
        ... on ContentfulSectionText {
          contentful_id
          id
          title
          text {
            raw
          }
        }
        ... on ContentfulSectionGallery {
          contentful_id
          id
          title
          media {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
          }
        }
        ... on ContentfulSectionHero {
          contentful_id
          id
          title
          backgroundImage {
            title
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: TRACED_SVG
                  height: 480
                  width: 1280
                  transformOptions: { fit: COVER, cropFocus: CENTER }
                )
              }
            }
          }
        }
      }
    }
  }
`

// export const query = graphql`
//   query WorkshopPageQuery {
//     workshopGallery: contentfulSectionGallery(
//       contentful_id: { eq: "2VoMHnX1lr7VVyNtvcp1AK" }
//     ) {
//       title
//       media {
//         localFile {
//           childImageSharp {
//             gatsbyImageData(placeholder: TRACED_SVG)
//           }
//         }
//       }
//     }
//   }
// `

export default IndexPage
